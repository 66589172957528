// interest.tsx
import React, { useState, useEffect, ChangeEvent } from 'react'
import { motion, AnimatePresence } from 'framer-motion'

// 1. Define the type of each question
type QuestionType = 'text' | 'email' | 'tel' | 'select'

// 2. Base interface for questions
interface BaseQuestion {
    id: string
    question: string
    type: QuestionType
}

// 3. Interface for select-type questions
interface SelectQuestion extends BaseQuestion {
    type: 'select'
    options: string[]
}

// 4. Interface for input-type questions
interface InputQuestion extends BaseQuestion {
    type: 'text' | 'email' | 'tel'
    placeholder: string
}

// 5. Union type for all questions
type Question = SelectQuestion | InputQuestion

// 6. Define the structure of the answers object
type Answers = Record<string, string>

// 7. Define the questions array with proper typing
const questions: Question[] = [
    { id: 'name', question: "What's your name?", type: 'text', placeholder: 'John Doe' },
    { id: 'email', question: 'How can we reach you via email?', type: 'email', placeholder: 'john@example.com' },
    { id: 'phone', question: "What's your phone number?", type: 'tel', placeholder: '+1 (555) 123-4567' },
    { id: 'assets', question: "What's the approximate value of your total assets?", type: 'text', placeholder: '$500,000' },
    { id: 'experience', question: 'How would you describe your investment experience?', type: 'select', options: ['Novice', 'Intermediate', 'Experienced', 'Expert'] },
]

// 8. Type guard to check if a question is SelectQuestion
const isSelectQuestion = (question: Question): question is SelectQuestion => {
    return question.type === 'select'
}

const ExpressInterestForm: React.FC = () => {
    // 9. Define state with proper types
    const [currentQuestion, setCurrentQuestion] = useState<number>(0)
    const [answers, setAnswers] = useState<Answers>({})
    const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    // 10. Handler for answering a question
    const handleAnswer = async (answer: string) => {
        setAnswers(prevAnswers => ({
            ...prevAnswers,
            [questions[currentQuestion].id]: answer
        }))

        if (currentQuestion < questions.length - 1) {
            setCurrentQuestion(prev => prev + 1)
        } else {
            // Last question answered, submit the form
            await submitForm({...answers, [questions[currentQuestion].id]: answer})
        }
    }

    // 11. Handler for going back to the previous question
    const handleBack = () => {
        if (currentQuestion > 0) {
            setCurrentQuestion(prev => prev - 1)
        }
    }

    // 12. Handler for key press events (specifically 'Enter')
    useEffect(() => {
        const handleKeyPress = (event: globalThis.KeyboardEvent) => {
            if (event.key === 'Enter' && !isSubmitted && !isSubmitting) {
                const input = document.querySelector('input, select') as HTMLInputElement | HTMLSelectElement | null
                if (input && input.value.trim() !== '') {
                    handleAnswer(input.value.trim())
                }
            }
        }

        window.addEventListener('keypress', handleKeyPress)
        return () => {
            window.removeEventListener('keypress', handleKeyPress)
        }
    }, [currentQuestion, isSubmitted, isSubmitting, answers])

    // 13. Animation variants for questions
    const questionVariants = {
        initial: {opacity: 0, y: 50},
        animate: {opacity: 1, y: 0},
        exit: {opacity: 0, y: -50}
    }

    // 14. Calculate progress percentage
    const progressPercentage = Math.round(((currentQuestion + (isSubmitted ? 1 : 0)) / questions.length) * 100)

    // 15. Get the current question
    const current = questions[currentQuestion]

    // 16. Function to submit the form data to the API
    const submitForm = async (data: Answers) => {
        setIsSubmitting(true)
        setError(null) // Reset any previous errors

        try {
            const response = await fetch('https://api.cciq.ai/api/express-interest', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            })

            if (!response.ok) {
                // Attempt to parse error message from response
                const errorData = await response.json()
                throw new Error(errorData.message || 'Failed to submit the form.')
            }

            // If submission is successful
            setIsSubmitted(true)
        } catch (err) {
            if (err instanceof Error) {
                setError(err.message)
            } else {
                setError('An unknown error occurred.')
            }
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <div
            className="min-h-screen bg-gradient-to-br from-gray-100 to-gray-200 flex items-center justify-center px-4 sm:px-6 lg:px-8">
            <motion.div
                initial={{opacity: 0, scale: 0.9}}
                animate={{opacity: 1, scale: 1}}
                transition={{duration: 0.5}}
                className="max-w-md w-full space-y-8 bg-white p-10 rounded-2xl shadow-xl"
            >
                <div>
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                        Express Your Interest
                    </h2>
                    <p className="mt-2 text-center text-sm text-gray-600">
                        Let's get to know you better, one step at a time.
                    </p>
                </div>

                <AnimatePresence mode="wait">
                    {!isSubmitted ? (
                        <motion.div
                            key={currentQuestion}
                            variants={questionVariants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{duration: 0.5}}
                        >
                            <h3 className="text-xl font-medium text-gray-900 mb-4">
                                {current.question}
                            </h3>
                            {isSelectQuestion(current) ? (
                                <select
                                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                                    onChange={(e: ChangeEvent<HTMLSelectElement>) => handleAnswer(e.target.value)}
                                    value={answers[current.id] || ''}
                                    disabled={isSubmitting}
                                >
                                    <option value="">Select an option</option>
                                    {current.options.map((option: string) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </select>
                            ) : (
                                <input
                                    type={current.type}
                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                    placeholder={current.placeholder}
                                    value={answers[current.id] || ''}
                                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                        setAnswers(prevAnswers => ({
                                            ...prevAnswers,
                                            [current.id]: e.target.value
                                        }))
                                    }
                                    disabled={isSubmitting}
                                />
                            )}
                            {error && (
                                <div className="mt-2 text-red-500 text-sm">
                                    {error}
                                </div>
                            )}
                            <div className="mt-4 flex justify-between">
                                {currentQuestion > 0 && (
                                    <button
                                        onClick={handleBack}
                                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-indigo-700 bg-indigo-100 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                        disabled={isSubmitting}
                                    >
                                        Back
                                    </button>
                                )}
                                <motion.button
                                    whileHover={{scale: 1.05}}
                                    whileTap={{scale: 0.95}}
                                    onClick={() => handleAnswer(answers[current.id] || '')}
                                    className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white ${
                                        answers[current.id]
                                            ? 'bg-indigo-600 hover:bg-indigo-700'
                                            : 'bg-indigo-300 cursor-not-allowed'
                                    } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`}
                                    disabled={!answers[current.id] || isSubmitting}
                                >
                                    {isSubmitting
                                        ? 'Submitting...'
                                        : currentQuestion === questions.length - 1
                                            ? 'Submit'
                                            : 'Next'}
                                </motion.button>
                            </div>
                        </motion.div>
                    ) : (
                        <motion.div
                            key="submitted"
                            initial={{opacity: 0, y: 50}}
                            animate={{opacity: 1, y: 0}}
                            transition={{duration: 0.5}}
                            className="text-center"
                        >
                            <h3 className="text-2xl font-bold text-gray-900 mb-4">Thank You!</h3>
                            <p className="text-gray-600 mb-6">We've received your information and will be in touch
                                soon.</p>
                            <motion.div
                                className="inline-block"
                                initial={{rotate: 0}}
                                animate={{rotate: 360}}
                                transition={{duration: 1, repeat: Infinity, ease: "linear"}}
                            >
                                🎉
                            </motion.div>
                        </motion.div>
                    )}
                </AnimatePresence>

                <div className="mt-6">
                    <div className="relative">
                        <div className="absolute inset-0 flex items-center" aria-hidden="true">
                            <div className="w-full border-t border-gray-300"></div>
                        </div>
                        <div className="relative flex justify-center text-sm">
                            <span className="px-2 bg-white text-gray-500">
                                Progress: {isSubmitted ? '100' : progressPercentage}%
                            </span>
                        </div>
                    </div>
                </div>
            </motion.div>
        </div>
    )
}

export default ExpressInterestForm