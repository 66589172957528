import React, { useState, useEffect, useCallback } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { AreaChart, Area, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { TrendingUp, DollarSign, Percent, ArrowUpRight, Clock, Zap, AlertCircle, Home, BarChart2, PieChart as PieChartIcon, Settings, Menu, X, Sun, Moon, LogOut } from 'lucide-react'
interface MenuItem {
    name: string;
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    href: string;
}
const performanceData = [
    { month: 'Sep', strategyReturn: 0.028, benchmarkReturn: 0.015, premium: 2500 },
    { month: 'Oct', strategyReturn: 0.035, benchmarkReturn: 0.02, premium: 3200 },
    { month: 'Nov', strategyReturn: 0.042, benchmarkReturn: 0.018, premium: 2800 },
    { month: 'Dec', strategyReturn: 0.038, benchmarkReturn: 0.022, premium: 3500 },
    { month: 'Jan', strategyReturn: 0.045, benchmarkReturn: 0.025, premium: 3800 },
    { month: 'Feb', strategyReturn: 0.052, benchmarkReturn: 0.03, premium: 3300 },
    { month: 'Mar', strategyReturn: 0.048, benchmarkReturn: 0.028, premium: 3900 },
    { month: 'Apr', strategyReturn: 0.055, benchmarkReturn: 0.032, premium: 4200 },
    { month: 'May', strategyReturn: 0.06, benchmarkReturn: 0.035, premium: 4500 },
    { month: 'Jun', strategyReturn: 0.065, benchmarkReturn: 0.038, premium: 4800 },
    { month: 'Jul', strategyReturn: 0.07, benchmarkReturn: 0.042, premium: 5100 },
    { month: 'Aug', strategyReturn: 0.075, benchmarkReturn: 0.045, premium: 5400 },
]

const upcomingOpportunities = [
    { ticker: 'AAPL', strikePrice: 150, expiry: '2024-12-20', premium: 3.50, confidence: 0.92, potentialReturn: 0.0233 },
    { ticker: 'MSFT', strikePrice: 300, expiry: '2024-12-06', premium: 4.25, confidence: 0.89, potentialReturn: 0.0142 },
    { ticker: 'GOOGL', strikePrice: 2800, expiry: '2024-11-15', premium: 15.00, confidence: 0.87, potentialReturn: 0.0054 },
    { ticker: 'AMZN', strikePrice: 3500, expiry: '2024-11-22', premium: 22.50, confidence: 0.85, potentialReturn: 0.0064 },
    { ticker: 'NVDA', strikePrice: 500, expiry: '2024-12-13', premium: 8.75, confidence: 0.90, potentialReturn: 0.0175 },
]

const recentTrades = [
    { date: 'Sep 20, 2024', action: 'Sell', option: 'AAPL 150 Call', premium: '+$350', status: 'Open' },
    { date: 'Sep 19, 2024', action: 'Sell', option: 'MSFT 300 Call', premium: '+$425', status: 'Open' },
    { date: 'Sep 18, 2024', action: 'Expired', option: 'GOOGL 2800 Call', premium: '+$1500', status: 'Closed' },
    { date: 'Sep 17, 2024', action: 'Assigned', option: 'AMZN 3200 Call', premium: '+$2250', status: 'Closed' },
]

const marketPulse = [
    { title: 'VIX', value: '18.5', trend: 'Decreasing', description: 'Market volatility is showing a downward trend. Consider adjusting strategies for lower volatility environment.' },
    { title: 'Sector Momentum', value: 'Technology', trend: 'Increasing', description: 'Technology sector showing strong momentum. Look for opportunities in tech stocks.' },
    { title: 'Put/Call Ratio', value: '0.75', trend: 'Stable', description: 'Current put/call ratio indicates balanced market sentiment. Maintain diverse strategy approach.' },
    { title: 'Yield Curve', value: 'Steepening', trend: 'Changing', description: 'Yield curve steepening may indicate improving economic outlook. Consider impact on various sectors.' },
]

const CustomTooltip = ({ active, payload, label, isDarkMode }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-4 rounded-xl shadow-lg border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} text-sm`}>
                <p className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'} mb-1`}>{`${label}`}</p>
                {payload.map((entry: any, index: number) => (
                    <p key={index} className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                        {`${entry.name === 'strategyReturn' ? 'Strategy Return' :
                            entry.name === 'benchmarkReturn' ? 'Benchmark Return' :
                                entry.name === 'premium' ? 'Premium' : entry.name}: ${
                            entry.name === 'premium' ? '$' + entry.value.toFixed(2) : (entry.value * 100).toFixed(2) + '%'
                        }`}
                    </p>
                ))}
            </div>
        )
    }
    return null
}

export default function CCIQDashboard() {
    const [selectedPeriod, setSelectedPeriod] = useState('YTD')
    const [currentTime, setCurrentTime] = useState(new Date('2024-09-23T16:21:07'))
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    const [isDarkMode, setIsDarkMode] = useState(true)

    useEffect(() => {
        const timer = setInterval(() => setCurrentTime(prevTime => new Date(prevTime.getTime() + 1000)), 1000)
        return () => clearInterval(timer)
    }, [])

    const menuItems: MenuItem[] = [
        { name: 'Dashboard', icon: Home, href: '/' },
        { name: 'Analytics', icon: BarChart2, href: '/analytics' },
        { name: 'Portfolio', icon: PieChartIcon, href: '/portfolio' },
        { name: 'Settings', icon: Settings, href: '/settings' },
    ];


    const toggleDarkMode = useCallback(() => {
        setIsDarkMode(prev => !prev)
    }, [])

    return (
        <div className={`min-h-screen ${isDarkMode ? 'bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100' : 'bg-gradient-to-br from-gray-50 to-white text-gray-900'} font-sans transition-colors duration-300`}>
            <nav className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-900/50' : 'bg-white/50'} backdrop-blur-xl`}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <span className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>CCIQ</span>
                        </div>
                        <div className="hidden md:flex items-center space-x-4">
                            {menuItems.map((item, index) => (
                                <motion.a
                                    key={index}
                                    href={item.href}
                                    className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'} px-3 py-2 text-sm font-medium transition-colors duration-200 flex items-center space-x-2`}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <item.icon className="w-5 h-5" />
                                    <span>{item.name}</span>
                                </motion.a>
                            ))}
                        </div>
                        <div className="flex items-center space-x-4">
                            <motion.button
                                className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-800 text-yellow-400' : 'bg-gray-200 text-gray-900'}`}
                                onClick={toggleDarkMode}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
                            </motion.button>
                            <motion.button
                                className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-200 text-gray-600'}`}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                onClick={() => {
                                    localStorage.clear()
                                    window.location.href = '/login'
                                }
                                }
                            >
                                <LogOut size={20} />
                            </motion.button>
                            <div className="md:hidden">
                                <button
                                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                                    className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}
                                >
                                    <span className="sr-only">Open main menu</span>
                                    {isMobileMenuOpen ? (
                                        <X className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Menu className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <AnimatePresence>
                {isMobileMenuOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        className={`md:hidden ${isDarkMode ? 'bg-gray-900/90' : 'bg-white/90'} backdrop-blur-xl`}
                    >
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {menuItems.map((item, index) => (
                                <a
                                    key={index}
                                    href="#"
                                    className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'} block px-3 py-2 rounded-md text-base font-medium`}
                                >
                                    <item.icon className="w-5 h-5 inline-block mr-2" />
                                    {item.name}
                                </a>
                            ))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <div className="flex flex-col sm:flex-row justify-between items-center mb-8">
                    <motion.h1
                        className={`text-4xl font-light tracking-tight ${isDarkMode ? 'text-white' : 'text-gray-900'} mb-4 sm:mb-0`}
                        initial={{ opacity: 0, y: -20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                    >
                        CCIQ Dashboard
                    </motion.h1>
                    <div className="flex items-center space-x-4">
                        <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                            Last updated: {currentTime.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true })}
                        </p>
                        <select
                            className={`px-3 py-1 ${isDarkMode ? 'bg-gray-800 border-gray-700 text-gray-300' : 'bg-white border-gray-300 text-gray-700'} border rounded-full text-sm focus:outline-none focus:ring-2 focus:ring-blue-500`}
                            value={selectedPeriod}
                            onChange={(e) => setSelectedPeriod(e.target.value)}
                        >
                            <option>YTD</option>
                            <option>1M</option>
                            <option>3M</option>
                            <option>6M</option>
                            <option>1Y</option>
                            <option>All Time</option>
                        </select>
                        <motion.button
                            className={`px-4 py-2 ${isDarkMode ? 'bg-white text-gray-900 hover:bg-gray-200' : 'bg-gray-900 text-white hover:bg-gray-800'} rounded-full text-sm font-medium transition-all duration-200`}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            Generate Report
                        </motion.button>
                    </div>
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                    {[
                        { title: 'Total Return', value: '+7.5%', change: '+3.0%', changeText: 'vs Benchmark', icon: TrendingUp },
                        { title: 'Premium Collected', value: '$47,200', change: '+12.5%',  changeText: 'vs Last Year', icon: DollarSign },
                        { title: 'Win Rate', value: '87.5%', change: '+2.3%', changeText: 'vs Historical Avg', icon: Percent },
                        { title: 'Avg Premium/Contract', value: '$425', change: '+5.2%', changeText: 'vs 30D Avg', icon: BarChart2 },
                    ].map((item, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{duration: 0.5, delay: index * 0.1 }}
                            className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border`}
                        >
                            <div className="flex items-center justify-between mb-4">
                                <h2 className={`text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>{item.title}</h2>
                                <item.icon className={`w-5 h-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                            </div>
                            <div className={`text-3xl font-light mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{item.value}</div>
                            <div className="flex items-center text-xs text-green-400 font-medium">
                                <ArrowUpRight size={12} className="mr-1" />
                                <span>{item.change} {item.changeText}</span>
                            </div>
                        </motion.div>
                    ))}
                </div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border`}
                    >
                        <h2 className={`text-xl font-light mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Strategy Performance</h2>
                        <div className="h-64">
                            <ResponsiveContainer width="100%" height="100%">
                                <AreaChart data={performanceData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                    <defs>
                                        <linearGradient id="colorStrategy" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor={isDarkMode ? '#ffffff' : '#000000'} stopOpacity={0.2}/>
                                            <stop offset="95%" stopColor={isDarkMode ? '#ffffff' : '#000000'} stopOpacity={0}/>
                                        </linearGradient>
                                        <linearGradient id="colorBenchmark" x1="0" y1="0" x2="0" y2="1">
                                            <stop offset="5%" stopColor="#9CA3AF" stopOpacity={0.2}/>
                                            <stop offset="95%" stopColor="#9CA3AF" stopOpacity={0}/>
                                        </linearGradient>
                                    </defs>
                                    <XAxis dataKey="month" axisLine={false} tickLine={false} tick={{ fill: isDarkMode ? '#9CA3AF' : '#4B5563', fontSize: 12 }} />
                                    <YAxis axisLine={false} tickLine={false} tick={{ fill: isDarkMode ? '#9CA3AF' : '#4B5563', fontSize: 12 }} />
                                    <CartesianGrid strokeDasharray="3 3" vertical={false} stroke={isDarkMode ? '#4B5563' : '#E5E7EB'} />
                                    <Tooltip content={<CustomTooltip isDarkMode={isDarkMode} />} />
                                    <Area type="monotone" dataKey="strategyReturn" stroke={isDarkMode ? '#ffffff' : '#000000'} strokeWidth={2} fillOpacity={1} fill="url(#colorStrategy)" />
                                    <Area type="monotone" dataKey="benchmarkReturn" stroke="#9CA3AF" strokeWidth={2} fillOpacity={1} fill="url(#colorBenchmark)" />
                                </AreaChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="flex justify-between items-center mt-4">
                            <div className="flex items-center">
                                <div className={`w-3 h-3 ${isDarkMode ? 'bg-white' : 'bg-gray-900'} rounded-full mr-2`}></div>
                                <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>Strategy</span>
                            </div>
                            <div className="flex items-center">
                                <div className="w-3 h-3 bg-gray-400 rounded-full mr-2"></div>
                                <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>Benchmark</span>
                            </div>
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                        className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border`}
                    >
                        <h2 className={`text-xl font-light mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Premium Collection Trend</h2>
                        <div className="h-64">
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart data={performanceData} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
                                    <CartesianGrid strokeDasharray="3 3" vertical={false} stroke={isDarkMode ? '#4B5563' : '#E5E7EB'} />
                                    <XAxis dataKey="month" axisLine={false} tickLine={false} tick={{ fill: isDarkMode ? '#9CA3AF' : '#4B5563', fontSize: 12 }} />
                                    <YAxis axisLine={false} tickLine={false} tick={{ fill: isDarkMode ? '#9CA3AF' : '#4B5563', fontSize: 12 }} />
                                    <Tooltip content={<CustomTooltip isDarkMode={isDarkMode} />} />
                                    <Bar dataKey="premium" fill={isDarkMode ? '#60A5FA' : '#3B82F6'} radius={[4, 4, 0, 0]} />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="flex justify-between items-center mt-4">
                            <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>Total Premium: $47,200</span>
                            <span className="text-xs text-green-400 font-medium">+12.5% vs Last Year</span>
                        </div>
                    </motion.div>
                </div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border mb-8`}
                >
                    <div className="flex justify-between items-center mb-6">
                        <h2 className={`text-xl font-light ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>AI-Recommended Opportunities</h2>
                        <motion.button
                            className={`text-sm ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'} font-medium transition-colors duration-200`}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            View All
                        </motion.button>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                            <tr className={`text-left text-xs font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} uppercase tracking-wider`}>
                                <th className="pb-3 pr-6">Ticker</th>
                                <th className="pb-3 pr-6">Strike</th>
                                <th className="pb-3 pr-6">Expiry</th>
                                <th className="pb-3 pr-6">Premium</th>
                                <th className="pb-3 pr-6">Potential Return</th>
                                <th className="pb-3">AI Confidence</th>
                            </tr>
                            </thead>
                            <tbody className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
                            {upcomingOpportunities.map((opportunity, index) => (
                                <motion.tr
                                    key={index}
                                    className="text-sm"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.3, delay: index * 0.1 }}
                                >
                                    <td className={`py-3 pr-6 whitespace-nowrap font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{opportunity.ticker}</td>
                                    <td className={`py-3 pr-6 whitespace-nowrap ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>${opportunity.strikePrice}</td>
                                    <td className={`py-3 pr-6 whitespace-nowrap ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>{opportunity.expiry}</td>
                                    <td className={`py-3 pr-6 whitespace-nowrap ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>${opportunity.premium.toFixed(2)}</td>
                                    <td className="py-3 pr-6 whitespace-nowrap text-green-400 font-medium">
                                        {(opportunity.potentialReturn * 100).toFixed(2)}%
                                    </td>
                                    <td className="py-3 whitespace-nowrap">
                                        <div className="flex items-center">
                                            <div className={`w-16 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded-full h-2 mr-2`}>
                                                <motion.div
                                                    className="bg-blue-500 h-2 rounded-full"
                                                    initial={{ width: 0 }}
                                                    animate={{ width: `${opportunity.confidence * 100}%` }}
                                                    transition={{ duration: 0.5, delay: 0.2 + index * 0.1 }}
                                                ></motion.div>
                                            </div>
                                            <span className={isDarkMode ? 'text-gray-300' : 'text-gray-500'}>{(opportunity.confidence * 100).toFixed(0)}%</span>
                                        </div>
                                    </td>
                                </motion.tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </motion.div>

                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.7 }}
                        className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border`}
                    >
                        <div className="flex justify-between items-center mb-6">
                            <h2 className={`text-xl font-light ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Recent Trades</h2>
                            <motion.button
                                className={`text-sm ${isDarkMode ? 'text-blue-400 hover:text-blue-300' : 'text-blue-600 hover:text-blue-500'} font-medium transition-colors duration-200`}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                View All
                            </motion.button>
                        </div>
                        <div className="space-y-4">
                            {recentTrades.map((trade, index) => (
                                <motion.div
                                    key={index}
                                    className={`flex items-center justify-between p-4 ${isDarkMode ? 'bg-gray-700/50 hover:bg-gray-700/70' : 'bg-gray-100/50 hover:bg-gray-200/50'} backdrop-blur-sm rounded-xl transition-colors duration-200`}
                                    initial={{ opacity: 0, x: -20 }}
                                    animate={{ opacity: 1, x: 0 }}
                                    transition={{ duration: 0.3, delay: index * 0.1 }}
                                >
                                    <div className="flex items-center space-x-4">
                                        <div className={`w-10 h-10 rounded-full flex items-center justify-center ${
                                            trade.action === 'Sell' ? 'bg-green-500/20 text-green-400' :
                                                trade.action === 'Expired' ? 'bg-gray-500/20 text-gray-400' :
                                                    'bg-blue-500/20 text-blue-400'
                                        }`}>
                                            {trade.action === 'Sell' ? <DollarSign size={20} /> :
                                                trade.action === 'Expired' ? <Clock size={20} /> :
                                                    <ArrowUpRight size={20} />}
                                        </div>
                                        <div>
                                            <p className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{trade.option}</p>
                                            <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>{trade.date}</p>
                                        </div>
                                    </div>
                                    <div className="text-right">
                                        <p className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{trade.premium}</p>
                                        <p className={`text-xs ${
                                            trade.status === 'Open' ? 'text-green-400' : isDarkMode ? 'text-gray-400' : 'text-gray-500'
                                        }`}>{trade.status}</p>
                                    </div>
                                </motion.div>
                            ))}
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{duration: 0.5, delay: 0.8 }}
                        className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border`}
                    >
                        <h2 className={`text-xl font-light mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Market Pulse</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            {marketPulse.map((insight, index) => (
                                <motion.div
                                    key={index}
                                    className={`p-4 ${isDarkMode ? 'bg-gray-700/50' : 'bg-gray-100/50'} backdrop-blur-sm rounded-xl transition-colors duration-200`}
                                    whileHover={{ scale: 1.02 }}
                                    transition={{ type: "spring", stiffness: 300 }}
                                >
                                    <h3 className={`text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-600'} mb-1`}>{insight.title}</h3>
                                    <p className={`text-lg font-light ${isDarkMode ? 'text-white' : 'text-gray-900'} mb-1`}>{insight.value}</p>
                                    <p className={`text-xs ${insight.trend === 'Increasing' ? 'text-green-400' : insight.trend === 'Decreasing' ? 'text-red-400' : 'text-yellow-400'}`}>
                                        {insight.trend}
                                    </p>
                                    <p className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} mt-2`}>{insight.description}</p>
                                </motion.div>
                            ))}
                        </div>
                    </motion.div>
                </div>
            </main>
        </div>
    )
}