// AdminDashboard.tsx
import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import {
    AreaChart,
    Area,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts'
import {
    Users as UsersIcon,
    DollarSign,
    UserPlus,
    ArrowUpRight,
    BarChart2,
    Edit,
    Trash2,
    RefreshCw,
    LogOut,
} from 'lucide-react'
import axios from 'axios'

// Define the User interface
interface User {
    id: number
    name: string
    email: string
    status: 'Active' | 'Inactive'
    plan: 'Basic' | 'Pro' | 'Enterprise'
    price: number
    subscriptionEnd: string
}

// Define the structure for subscription data
const subscriptionData = [
    { month: 'Jan', activeSubscriptions: 100, revenue: 5000 },
    { month: 'Feb', activeSubscriptions: 120, revenue: 6000 },
    { month: 'Mar', activeSubscriptions: 150, revenue: 7500 },
    { month: 'Apr', activeSubscriptions: 180, revenue: 9000 },
    { month: 'May', activeSubscriptions: 200, revenue: 10000 },
    { month: 'Jun', activeSubscriptions: 220, revenue: 11000 },
    { month: 'Jul', activeSubscriptions: 240, revenue: 12000 },
    { month: 'Aug', activeSubscriptions: 260, revenue: 13000 },
    { month: 'Sep', activeSubscriptions: 280, revenue: 14000 },
    { month: 'Oct', activeSubscriptions: 300, revenue: 15000 },
    { month: 'Nov', activeSubscriptions: 320, revenue: 16000 },
    { month: 'Dec', activeSubscriptions: 350, revenue: 17500 },
]

// Custom Tooltip component for charts
interface CustomTooltipProps {
    active?: boolean
    payload?: any[]
    label?: string
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-100 text-sm">
                <p className="font-medium text-gray-900 mb-1">{`${label}`}</p>
                {payload.map((entry, index) => (
                    <p key={index} className="text-gray-600">
                        {`${entry.name}: $${entry.value}`}
                    </p>
                ))}
            </div>
        )
    }
    return null
}

export default function AdminDashboard() {
    const [selectedPeriod, setSelectedPeriod] = useState<string>('YTD')
    const [users, setUsers] = useState<User[]>([])
    const [filteredUsers, setFilteredUsers] = useState<User[]>([])
    const [isAddUserModalOpen, setIsAddUserModalOpen] = useState<boolean>(false)
    const [newUser, setNewUser] = useState<{
        name: string
        email: string
        password: string
        plan: 'Basic' | 'Pro' | 'Enterprise'
        price: number
    }>({ name: '', email: '', password: '', plan: 'Basic', price: 19.99 })
    const [isEditUserModalOpen, setIsEditUserModalOpen] = useState<boolean>(false)
    const [editingUser, setEditingUser] = useState<User | null>(null)
    const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState<boolean>(false)
    const [userToDelete, setUserToDelete] = useState<User | null>(null)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [statusFilter, setStatusFilter] = useState<string>('All')
    const [loading, setLoading] = useState<boolean>(false)
    const [error, setError] = useState<string | null>(null)

    const token = localStorage.getItem('token') || ''

    useEffect(() => {
        fetchUsers()
    }, [])

    useEffect(() => {
        const filtered = users.filter(
            (user) =>
                (user.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    user.email.toLowerCase().includes(searchTerm.toLowerCase())) &&
                (statusFilter === 'All' || user.status === statusFilter)
        )
        setFilteredUsers(filtered)
    }, [users, searchTerm, statusFilter])

    const fetchUsers = async () => {
        setLoading(true)
        try {
            const response = await axios.get('https://api.cciq.ai/api/users', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            setUsers(response.data.users)
            setError(null)
        } catch (err: any) {
            console.error(err)
            setError('Failed to fetch users.')
        } finally {
            setLoading(false)
        }
    }

    const handleAddUser = async () => {
        if (!newUser.name || !newUser.email || !newUser.password) {
            setError('Please fill in all required fields.')
            return
        }

        try {
            // Register the user
            const registerResponse = await axios.post(
                'https://api.cciq.ai/api/register',
                {
                    email: newUser.email,
                    password: newUser.password,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            )

            // Create a subscription for the user
            const subscribeResponse = await axios.post(
                'https://api.cciq.ai/api/admin/subscribe',
                {
                    user_email: newUser.email,
                    name: newUser.name,
                    plan: newUser.plan,
                    price_per_month: newUser.price,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )

            // Refresh the users list
            fetchUsers()

            // Close the modal and reset newUser state
            setIsAddUserModalOpen(false)
            setNewUser({ name: '', email: '', password: '', plan: 'Basic', price: 19.99 })
            setError(null)
        } catch (err: any) {
            console.error(err)
            setError(err.response?.data?.message || 'Failed to add user. Please try again.')
        }
    }

    const handleEditUser = async () => {
        if (!editingUser) return

        try {
            // Update user information via API
            await axios.put(
                `https://api.cciq.ai/api/users/${editingUser.id}`,
                {
                    name: editingUser.name,
                    email: editingUser.email,
                    plan: editingUser.plan,
                    price: editingUser.price,
                    subscriptionEnd: editingUser.subscriptionEnd,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            )

            // Refresh the users list
            fetchUsers()

            // Close the modal and reset editingUser state
            setIsEditUserModalOpen(false)
            setEditingUser(null)
            setError(null)
        } catch (err: any) {
            console.error(err)
            setError(err.response?.data?.message || 'Failed to update user. Please try again.')
        }
    }

    const handleDeleteUser = (id: number) => {
        const user = users.find((user) => user.id === id) || null
        setUserToDelete(user)
        setIsDeleteConfirmationOpen(true)
    }

    const confirmDeleteUser = async () => {
        if (!userToDelete) return

        try {
            // Delete user via API
            await axios.delete(`https://api.cciq.ai/api/users/${userToDelete.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })

            // Refresh the users list
            fetchUsers()

            // Close the confirmation modal and reset userToDelete state
            setIsDeleteConfirmationOpen(false)
            setUserToDelete(null)
            setError(null)
        } catch (err: any) {
            console.error(err)
            setError(err.response?.data?.message || 'Failed to delete user. Please try again.')
        }
    }

    const handleLogout = () => {
        // Implement logout logic here
        localStorage.removeItem('token')
        window.location.reload()
    }

    // Calculate statistics
    const totalUsers = users.length
    const activeSubscriptions = users.filter(user => user.status === 'Active').length
    const monthlyRevenue = users.reduce((acc, user) => acc + user.price, 0)
    const avgSubscriptionValue = users.length > 0 ? monthlyRevenue / users.length : 0

    return (
        <div className="flex h-screen bg-white font-sans text-gray-900">
            {/* Main Content */}
            <main className="flex-1 overflow-x-hidden overflow-y-auto bg-white">
                <header className="sticky top-0 z-10 bg-white border-b border-gray-200">
                    <div className="flex items-center justify-between h-16 px-6">
                        <h1 className="text-2xl font-light">Admin Dashboard</h1>
                        <button
                            onClick={handleLogout}
                            className="px-4 py-2 text-sm font-medium text-white bg-black rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                        >
                            <LogOut className="w-4 h-4 mr-2 inline-block" />
                            Logout
                        </button>
                    </div>
                </header>

                <div className="px-6 py-8">
                    <div className="flex justify-between items-center mb-8">
                        <div>
                            <h2 className="text-2xl font-light">User Management</h2>
                            <p className="text-sm text-gray-500">Manage users and their subscriptions</p>
                        </div>
                        <div className="flex items-center space-x-4">
                            <select
                                className="px-3 py-1 border border-gray-300 rounded-lg text-sm focus:outline-none focus:border-black bg-white"
                                value={selectedPeriod}
                                onChange={(e) => setSelectedPeriod(e.target.value)}
                            >
                                <option>YTD</option>
                                <option>1M</option>
                                <option>3M</option>
                                <option>6M</option>
                                <option>1Y</option>
                                <option>All Time</option>
                            </select>
                            <motion.button
                                className="px-4 py-1 bg-black text-white rounded-full text-sm font-medium hover:bg-gray-800 transition-all duration-200 flex items-center"
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={() => setIsAddUserModalOpen(true)}
                            >
                                <UserPlus size={16} className="mr-2" />
                                Add User
                            </motion.button>
                        </div>
                    </div>

                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                        {[
                            {
                                title: 'Total Users',
                                value: totalUsers.toString(),
                                change: '+3.2%',
                                icon: UsersIcon,
                            },
                            {
                                title: 'Active Subscriptions',
                                value: activeSubscriptions.toString(),
                                change: '+2.1%',
                                icon: RefreshCw,
                            },
                            {
                                title: 'Monthly Revenue',
                                value: `$${monthlyRevenue.toFixed(2)}`,
                                change: '+5.3%',
                                icon: DollarSign,
                            },
                            {
                                title: 'Avg. Subscription Value',
                                value: `$${avgSubscriptionValue.toFixed(2)}`,
                                change: '+1.5%',
                                icon: BarChart2,
                            },
                        ].map((item, index) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                className="bg-gray-50 p-6 rounded-2xl hover:shadow-md transition-all duration-200"
                            >
                                <div className="flex items-center justify-between mb-2">
                                    <h2 className="text-sm font-medium text-gray-600">{item.title}</h2>
                                    <item.icon className="w-4 h-4 text-gray-400" />
                                </div>
                                <div className="text-3xl font-light mb-1">{item.value}</div>
                                <div className="flex items-center text-xs text-green-600 font-medium">
                                    <ArrowUpRight size={12} className="mr-1" />
                                    <span>{item.change} vs Last Month</span>
                                </div>
                            </motion.div>
                        ))}
                    </div>

                    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.4 }}
                            className="bg-white p-6 rounded-2xl shadow-sm hover:shadow-md transition-all duration-200"
                        >
                            <h2 className="text-xl font-light mb-6">Subscription Trend</h2>
                            <div className="h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <AreaChart
                                        data={subscriptionData}
                                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                                    >
                                        <defs>
                                            <linearGradient
                                                id="colorSubscriptions"
                                                x1="0"
                                                y1="0"
                                                x2="0"
                                                y2="1"
                                            >
                                                <stop offset="5%" stopColor="#000000" stopOpacity={0.1} />
                                                <stop offset="95%" stopColor="#000000" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>
                                        <XAxis
                                            dataKey="month"
                                            axisLine={false}
                                            tickLine={false}
                                            tick={{ fill: '#6B7280', fontSize: 12 }}
                                        />
                                        <YAxis
                                            axisLine={false}
                                            tickLine={false}
                                            tick={{ fill: '#6B7280', fontSize: 12 }}
                                        />
                                        <CartesianGrid
                                            strokeDasharray="3 3"
                                            vertical={false}
                                            stroke="#E5E7EB"
                                        />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Area
                                            type="monotone"
                                            dataKey="activeSubscriptions"
                                            stroke="#000000"
                                            fillOpacity={1}
                                            fill="url(#colorSubscriptions)"
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            </div>
                        </motion.div>
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.5 }}
                            className="bg-white p-6 rounded-2xl shadow-sm hover:shadow-md transition-all duration-200"
                        >
                            <h2 className="text-xl font-light mb-6">Revenue Trend</h2>
                            <div className="h-64">
                                <ResponsiveContainer width="100%" height="100%">
                                    <BarChart
                                        data={subscriptionData}
                                        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                                    >
                                        <CartesianGrid
                                            strokeDasharray="3 3"
                                            vertical={false}
                                            stroke="#E5E7EB"
                                        />
                                        <XAxis
                                            dataKey="month"
                                            axisLine={false}
                                            tickLine={false}
                                            tick={{ fill: '#6B7280', fontSize: 12 }}
                                        />
                                        <YAxis
                                            axisLine={false}
                                            tickLine={false}
                                            tick={{ fill: '#6B7280', fontSize: 12 }}
                                        />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Bar
                                            dataKey="revenue"
                                            fill="#000000"
                                            radius={[4, 4, 0, 0]}
                                        />
                                    </BarChart>
                                </ResponsiveContainer>
                            </div>
                        </motion.div>
                    </div>

                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.6 }}
                        className="bg-white p-6 rounded-2xl shadow-sm hover:shadow-md transition-all duration-200 mb-8"
                    >
                        <div className="flex justify-between items-center mb-6">
                            <h2 className="text-xl font-light">User List</h2>
                            <div className="flex items-center space-x-2">
                                <input
                                    type="text"
                                    placeholder="Search users..."
                                    className="px-3 py-1 border border-gray-300 rounded-lg text-sm focus:outline-none focus:border-black"
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <select
                                    className="px-3 py-1 border border-gray-300 rounded-lg text-sm focus:outline-none focus:border-black bg-white"
                                    value={statusFilter}
                                    onChange={(e) => setStatusFilter(e.target.value)}
                                >
                                    <option value="All">All Status</option>
                                    <option value="Active">Active</option>
                                    <option value="Inactive">Inactive</option>
                                </select>
                            </div>
                        </div>
                        {loading ? (
                            <p className="text-center text-gray-500">Loading users...</p>
                        ) : error ? (
                            <p className="text-center text-red-500">{error}</p>
                        ) : (
                            <div className="overflow-x-auto">
                                <table className="w-full">
                                    <thead>
                                    <tr className="text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        <th className="pb-3 pr-6">Name</th>
                                        <th className="pb-3 pr-6">Email</th>
                                        <th className="pb-3 pr-6">Status</th>
                                        <th className="pb-3 pr-6">Plan</th>
                                        <th className="pb-3 pr-6">Price</th>
                                        <th className="pb-3 pr-6">Subscription End</th>
                                        <th className="pb-3">Actions</th>
                                    </tr>
                                    </thead>
                                    <tbody className="divide-y divide-gray-100">
                                    {filteredUsers.map((user, index) => (
                                        <motion.tr
                                            key={user.id}
                                            className="text-sm hover:bg-gray-50 transition-colors duration-200"
                                            initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            transition={{ duration: 0.3, delay: index * 0.05 }}
                                        >
                                            <td className="py-3 pr-6 whitespace-nowrap font-medium">
                                                {user.name}
                                            </td>
                                            <td className="py-3 pr-6 whitespace-nowrap text-gray-500">
                                                {user.email}
                                            </td>
                                            <td className="py-3 pr-6 whitespace-nowrap">
                                                    <span
                                                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                                                            user.status === 'Active'
                                                                ? 'bg-green-100 text-green-800'
                                                                : 'bg-red-100 text-red-800'
                                                        }`}
                                                    >
                                                        {user.status}
                                                    </span>
                                            </td>
                                            <td className="py-3 pr-6 whitespace-nowrap text-gray-500">
                                                {user.plan}
                                            </td>
                                            <td className="py-3 pr-6 whitespace-nowrap text-gray-500">
                                                ${user.price.toFixed(2)}
                                            </td>
                                            <td className="py-3 pr-6 whitespace-nowrap text-gray-500">
                                                {user.subscriptionEnd}
                                            </td>
                                            <td className="py-3 whitespace-nowrap text-sm font-medium">
                                                <button
                                                    onClick={() => {
                                                        setEditingUser(user)
                                                        setIsEditUserModalOpen(true)
                                                    }}
                                                    className="text-indigo-600 hover:text-indigo-900 mr-3"
                                                >
                                                    <Edit size={16} />
                                                </button>
                                                <button
                                                    onClick={() => handleDeleteUser(user.id)}
                                                    className="text-red-600 hover:text-red-900"
                                                >
                                                    <Trash2 size={16} />
                                                </button>
                                            </td>
                                        </motion.tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </motion.div>
                </div>
            </main>

            {/* Add User Modal */}
            <AnimatePresence>
                {isAddUserModalOpen && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    >
                        <motion.div
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.9, opacity: 0 }}
                            className="bg-white p-6 rounded-lg w-96"
                        >
                            <h2 className="text-xl font-semibold mb-4">Add New User</h2>
                            {error && <p className="text-red-500 mb-4">{error}</p>}
                            <div className="space-y-4">
                                <div>
                                    <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        value={newUser.name}
                                        onChange={(e) =>
                                            setNewUser({ ...newUser, name: e.target.value })
                                        }
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-black focus:border-black"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        value={newUser.email}
                                        onChange={(e) =>
                                            setNewUser({ ...newUser, email: e.target.value })
                                        }
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-black focus:border-black"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                        Password
                                    </label>
                                    <input
                                        type="password"
                                        id="password"
                                        value={newUser.password}
                                        onChange={(e) =>
                                            setNewUser({ ...newUser, password: e.target.value })
                                        }
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-black focus:border-black"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="plan" className="block text-sm font-medium text-gray-700">
                                        Plan
                                    </label>
                                    <select
                                        id="plan"
                                        value={newUser.plan}
                                        onChange={(e) =>
                                            setNewUser({
                                                ...newUser,
                                                plan: e.target.value as 'Basic' | 'Pro' | 'Enterprise',
                                                price:
                                                    e.target.value === 'Basic'
                                                        ? 19.99
                                                        : e.target.value === 'Pro'
                                                            ? 49.99
                                                            : 99.99,
                                            })
                                        }
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-black focus:border-black"
                                    >
                                        <option value="Basic">Basic</option>
                                        <option value="Pro">Pro</option>
                                        <option value="Enterprise">Enterprise</option>
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                                        Price
                                    </label>
                                    <input
                                        type="number"
                                        id="price"
                                        value={newUser.price}
                                        onChange={(e) =>
                                            setNewUser({
                                                ...newUser,
                                                price: parseFloat(e.target.value) || 0,
                                            })
                                        }
                                        step="0.01"
                                        min="0"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-black focus:border-black"
                                        readOnly
                                    />
                                </div>
                            </div>
                            <div className="mt-6 flex justify-end space-x-3">
                                <button
                                    onClick={() => {
                                        setIsAddUserModalOpen(false)
                                        setError(null)
                                    }}
                                    className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleAddUser}
                                    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                                >
                                    Add User
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Edit User Modal */}
            <AnimatePresence>
                {isEditUserModalOpen && editingUser && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    >
                        <motion.div
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.9, opacity: 0 }}
                            className="bg-white p-6 rounded-lg w-96"
                        >
                            <h2 className="text-xl font-semibold mb-4">Edit User</h2>
                            {error && <p className="text-red-500 mb-4">{error}</p>}
                            <div className="space-y-4">
                                <div>
                                    <label htmlFor="edit-name" className="block text-sm font-medium text-gray-700">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        id="edit-name"
                                        value={editingUser.name}
                                        onChange={(e) =>
                                            setEditingUser({
                                                ...editingUser,
                                                name: e.target.value,
                                            })
                                        }
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-black focus:border-black"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="edit-email" className="block text-sm font-medium text-gray-700">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="edit-email"
                                        value={editingUser.email}
                                        onChange={(e) =>
                                            setEditingUser({
                                                ...editingUser,
                                                email: e.target.value,
                                            })
                                        }
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-black focus:border-black"
                                    />
                                </div>
                                <div>
                                    <label htmlFor="edit-plan" className="block text-sm font-medium text-gray-700">
                                        Plan
                                    </label>
                                    <select
                                        id="edit-plan"
                                        value={editingUser.plan}
                                        onChange={(e) =>
                                            setEditingUser({
                                                ...editingUser,
                                                plan: e.target.value as 'Basic' | 'Pro' | 'Enterprise',
                                                price:
                                                    e.target.value === 'Basic'
                                                        ? 19.99
                                                        : e.target.value === 'Pro'
                                                            ? 49.99
                                                            : 99.99,
                                            })
                                        }
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-black focus:border-black"
                                    >
                                        <option value="Basic">Basic</option>
                                        <option value="Pro">Pro</option>
                                        <option value="Enterprise">Enterprise</option>
                                    </select>
                                </div>
                                <div>
                                    <label htmlFor="edit-price" className="block text-sm font-medium text-gray-700">
                                        Price
                                    </label>
                                    <input
                                        type="number"
                                        id="edit-price"
                                        value={editingUser.price}
                                        onChange={(e) =>
                                            setEditingUser({
                                                ...editingUser,
                                                price: parseFloat(e.target.value) || 0,
                                            })
                                        }
                                        step="0.01"
                                        min="0"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-black focus:border-black"
                                        readOnly
                                    />
                                </div>
                                <div>
                                    <label
                                        htmlFor="edit-subscription-end"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Subscription End
                                    </label>
                                    <input
                                        type="date"
                                        id="edit-subscription-end"
                                        value={editingUser.subscriptionEnd}
                                        onChange={(e) =>
                                            setEditingUser({
                                                ...editingUser,
                                                subscriptionEnd: e.target.value,
                                            })
                                        }
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-black focus:border-black"
                                    />
                                </div>
                            </div>
                            <div className="mt-6 flex justify-end space-x-3">
                                <button
                                    onClick={() => {
                                        setIsEditUserModalOpen(false)
                                        setEditingUser(null)
                                        setError(null)
                                    }}
                                    className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleEditUser}
                                    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                                >
                                    Save Changes
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Delete Confirmation Modal */}
            <AnimatePresence>
                {isDeleteConfirmationOpen && userToDelete && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
                    >
                        <motion.div
                            initial={{ scale: 0.9, opacity: 0 }}
                            animate={{ scale: 1, opacity: 1 }}
                            exit={{ scale: 0.9, opacity: 0 }}
                            className="bg-white p-6 rounded-lg w-96"
                        >
                            <h2 className="text-xl font-semibold mb-4">Confirm Delete</h2>
                            <p className="mb-6">
                                Are you sure you want to delete the user{' '}
                                <span className="font-medium">{userToDelete.name}</span>? This action
                                cannot be undone.
                            </p>
                            <div className="flex justify-end space-x-3">
                                <button
                                    onClick={() => setIsDeleteConfirmationOpen(false)}
                                    className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={confirmDeleteUser}
                                    className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                                >
                                    Delete
                                </button>
                            </div>
                        </motion.div>
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
}
