// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CCIQDashboard from './CCIQDashboard';
import Login from './login-page';
import PrivateRoute from './PrivateRoute'; // Ensure the path is correct
import AnalyticsPage from "./analytics";
import PortfolioPage from "./portfolio";
import {Settings} from "lucide-react";
import SettingsPage from "./settings";
import ExpressInterestForm from "./interest";
import SubscriptionManager from "./admin";
import AdminRoute from "./AdminRoute";
import AdminDashboard from "./admin";
const App: React.FC = () => {
    return (
        <Router>
            <Routes>
                {/* Protect the Dashboard route */}
                <Route
                    path="/"
                    element={
                        <PrivateRoute>
                            <CCIQDashboard />
                        </PrivateRoute>
                    }
                />

                <Route
                    path="/analytics"
                    element={
                        <PrivateRoute>
                            <AnalyticsPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/portfolio"
                    element={
                        <PrivateRoute>
                            <PortfolioPage />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="/settings"
                    element={
                        <PrivateRoute>
                            <SettingsPage />
                        </PrivateRoute>
                    }
                />

                {/* Public Login route */}
                <Route path="/login" element={<Login />} />
                <Route path="/admin" element={<AdminRoute element={<AdminDashboard />} />} />
                <Route path="/interest" element={<ExpressInterestForm />} />
            </Routes>
        </Router>
    );
};

export default App;
