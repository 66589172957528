import React, { useState, useEffect } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { User, CreditCard, Tag, Bell, Shield, HelpCircle, LogOut, ChevronRight, Plus, Trash2, Check, X, Home, BarChart2, PieChart as PieChartIcon, Settings, Menu, Sun, Moon, AlertCircle, Save } from 'lucide-react'

// Mock data
const userProfile = {
    name: 'John Doe',
    email: 'john.doe@example.com',
    plan: 'Elite',
    nextBillingDate: '2024-12-01',
    coveredTickers: ['AAPL', 'GOOGL', 'MSFT', 'AMZN', 'TSLA'],
    maxTickers: 10,
    lastTickerChangeDate: '2024-07-15',
}

export default function SettingsPage() {
    const [isDarkMode, setIsDarkMode] = useState(true)
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)
    const [newTicker, setNewTicker] = useState('')
    const [tickerChangeError, setTickerChangeError] = useState('')
    const [pendingTickers, setPendingTickers] = useState<string[]>([])
    const [removedTickers, setRemovedTickers] = useState<string[]>([])

    useEffect(() => {
        setPendingTickers([...userProfile.coveredTickers])
    }, [])

    const menuItems = [
        { name: 'Dashboard', icon: Home, href: '/' },
        { name: 'Analytics', icon: BarChart2, href: '/analytics' },
        { name: 'Portfolio', icon: PieChartIcon, href: '/portfolio' },
        { name: 'Settings', icon: Settings, href: '/settings' },
    ]

    const handleAddTicker = () => {
        if (pendingTickers.length >= userProfile.maxTickers) {
            setTickerChangeError(`You've reached the maximum number of tickers (${userProfile.maxTickers}).`)
            return
        }
        if (newTicker && !pendingTickers.includes(newTicker)) {
            setPendingTickers([...pendingTickers, newTicker])
            setNewTicker('')
            setTickerChangeError('')
        }
    }

    const handleRemoveTicker = (ticker: string) => {
        setPendingTickers(pendingTickers.filter(t => t !== ticker))
        if (!userProfile.coveredTickers.includes(ticker)) {
            setRemovedTickers(removedTickers.filter(t => t !== ticker))
        } else if (!removedTickers.includes(ticker)) {
            setRemovedTickers([...removedTickers, ticker])
        }
    }

    const handleSubmitChanges = () => {
        // Here you would typically make an API call to update the user's tickers
        userProfile.coveredTickers = [...pendingTickers]
        userProfile.lastTickerChangeDate = new Date().toISOString().split('T')[0]
        setRemovedTickers([])
        setTickerChangeError('Ticker changes saved successfully!')
    }

    const hasChanges = () => {
        return pendingTickers.length !== userProfile.coveredTickers.length ||
            pendingTickers.some(ticker => !userProfile.coveredTickers.includes(ticker)) ||
            userProfile.coveredTickers.some(ticker => !pendingTickers.includes(ticker))
    }

    return (
        <div className={`min-h-screen ${isDarkMode ? 'bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100' : 'bg-gradient-to-br from-gray-50 to-white text-gray-900'} font-sans transition-colors duration-300`}>
            <nav className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-900/50' : 'bg-white/50'} backdrop-blur-xl`}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <span className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>CCIQ</span>
                        </div>
                        <div className="hidden md:flex items-center space-x-4">
                            {menuItems.map((item, index) => (
                                <motion.a
                                    key={index}
                                    href={item.href}
                                    className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'} px-3 py-2 text-sm font-medium transition-colors duration-200 flex items-center space-x-2`}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <item.icon className="w-5 h-5" />
                                    <span>{item.name}</span>
                                </motion.a>
                            ))}
                        </div>
                        <div className="flex items-center space-x-4">
                            <motion.button
                                className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-800 text-yellow-400' : 'bg-gray-200 text-gray-900'}`}
                                onClick={() => setIsDarkMode(!isDarkMode)}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
                            </motion.button>
                            <motion.button
                                className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-200 text-gray-600'}`}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                <LogOut size={20} />
                            </motion.button>
                            <div className="md:hidden">
                                <button
                                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                                    className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}
                                >
                                    <span className="sr-only">Open main menu</span>
                                    {isMobileMenuOpen ? (
                                        <X className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Menu className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <AnimatePresence>
                {isMobileMenuOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        className={`md:hidden ${isDarkMode ? 'bg-gray-900/90' : 'bg-white/90'} backdrop-blur-xl`}
                    >
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {menuItems.map((item, index) => (
                                <a
                                    key={index}
                                    href={item.href}
                                    className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'} block px-3 py-2 rounded-md text-base font-medium`}
                                >
                                    <item.icon className="w-5 h-5 inline-block mr-2" />
                                    {item.name}
                                </a>
                            ))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <main className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <motion.h1
                    className={`text-4xl font-light tracking-tight ${isDarkMode ? 'text-white' : 'text-gray-900'} mb-8`}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    Account Settings
                </motion.h1>

                <div className="space-y-8">
                    <motion.section
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        className={`${isDarkMode ? 'bg-gray-800/50' : 'bg-white/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}
                    >
                        <h2 className={`text-xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Account Information</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <label className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Name</label>
                                <p className={`mt-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{userProfile.name}</p>
                            </div>
                            <div>
                                <label className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Email</label>
                                <p className={`mt-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{userProfile.email}</p>
                            </div>
                            <div>
                                <label className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Current Plan</label>
                                <p className={`mt-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{userProfile.plan}</p>
                            </div>
                            <div>
                                <label className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>Next Billing Date</label>
                                <p className={`mt-1 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{userProfile.nextBillingDate}</p>
                            </div>
                        </div>
                        <div className="mt-6 flex flex-wrap gap-4">
                            <motion.button
                                className={`px-4 py-2 rounded-md text-sm font-medium ${
                                    isDarkMode
                                        ? 'bg-blue-600/20 text-blue-400 hover:bg-blue-600/30'
                                        : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                                } transition-colors duration-200`}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                Upgrade Plan
                            </motion.button>
                            <motion.button
                                className={`px-4 py-2 rounded-md text-sm font-medium ${
                                    isDarkMode
                                        ? 'bg-red-600/20 text-red-400 hover:bg-red-600/30'
                                        : 'bg-red-100 text-red-700 hover:bg-red-200'
                                } transition-colors duration-200`}
                                whileHover={{ scale: 1.05 }}
                                whileTap={{ scale: 0.95 }}
                            >
                                Cancel Subscription
                            </motion.button>
                        </div>
                    </motion.section>

                    <motion.section
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                        className={`${isDarkMode ? 'bg-gray-800/50' : 'bg-white/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}
                    >
                        <h2 className={`text-xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Covered Tickers</h2>
                        <p className={`text-sm mb-4 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                            Your plan allows up to {userProfile.maxTickers} tickers. Last change: {userProfile.lastTickerChangeDate}
                        </p>
                        <div className="space-y-4">
                            <div className="flex flex-wrap gap-2">
                                {pendingTickers.map((ticker, index) => (
                                    <div key={index} className={`flex items-center space-x-2 px-3 py-1 rounded-full ${isDarkMode ? 'bg-gray-700 text-gray-200' : 'bg-gray-200 text-gray-700'}`}>
                                        <span>{ticker}</span>
                                        <button onClick={() => handleRemoveTicker(ticker)} className="focus:outline-none">
                                            <X size={16} className={isDarkMode ? 'text-gray-400 hover:text-gray-200' : 'text-gray-500 hover:text-gray-700'} />
                                        </button>
                                    </div>
                                ))}
                            </div>
                            <div className="flex space-x-2">
                                <input
                                    type="text"
                                    value={newTicker}
                                    onChange={(e) => setNewTicker(e.target.value.toUpperCase())}
                                    placeholder="Add new ticker"
                                    className={`flex-grow px-3 py-2 rounded-md ${isDarkMode ? 'bg-gray-700 text-white placeholder-gray-400' : 'bg-gray-100 text-gray-900 placeholder-gray-500'} focus:outline-none focus:ring-2 focus:ring-blue-500`}
                                />
                                <motion.button
                                    onClick={handleAddTicker}
                                    className={`px-4 py-2 rounded-md ${
                                        isDarkMode
                                            ? 'bg-blue-600/20 text-blue-400 hover:bg-blue-600/30'
                                            : 'bg-blue-100 text-blue-700 hover:bg-blue-200'
                                    } transition-colors duration-200`}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <Plus size={20} />
                                </motion.button>
                            </div>
                            {tickerChangeError && (
                                <div className={`flex items-center space-x-2 text-sm ${isDarkMode ? 'text-red-400' : 'text-red-600'}`}>
                                    <AlertCircle size={16} />
                                    <span>{tickerChangeError}</span>
                                </div>
                            )}
                            <p className={`text-sm ${isDarkMode ? 'text-gray-400' : 'text-gray-600'}`}>
                                {pendingTickers.length} / {userProfile.maxTickers} tickers used
                            </p>
                            {hasChanges() && (
                                <motion.button
                                    onClick={handleSubmitChanges}
                                    className={`w-full mt-4 px-4 py-2 rounded-md ${
                                        isDarkMode
                                            ? 'bg-green-600/20 text-green-400 hover:bg-green-600/30'
                                            : 'bg-green-100 text-green-700 hover:bg-green-200'
                                    } transition-colors duration-200 flex items-center justify-center`}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <Save size={20} className="mr-2" />
                                    Save Ticker Changes
                                </motion.button>
                            )}
                        </div>
                    </motion.section>

                    <motion.section
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className={`${isDarkMode ? 'bg-gray-800/50' : 'bg-white/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}
                    >
                        <h2 className={`text-xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Notification Settings</h2>
                        <div className="space-y-4">
                            {['Email Alerts', 'Push Notifications', 'SMS Alerts'].map((setting, index) => (
                                <div key={index} className="flex items-center justify-between">
                                    <span className={isDarkMode ? 'text-gray-200' : 'text-gray-700'}>{setting}</span>
                                    <label className="switch">
                                        <input type="checkbox" defaultChecked={index !== 2} />
                                        <span className={`slider round ${isDarkMode ? 'bg-gray-600' : 'bg-gray-300'}`}></span>
                                    </label>
                                </div>
                            ))}
                        </div>
                    </motion.section>

                    <motion.section
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                        className={`${isDarkMode ? 'bg-gray-800/50' : 'bg-white/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}
                    >
                        <h2 className={`text-xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Security Settings</h2>
                        <div className="space-y-4">
                            <button className={`w-full text-left px-4 py-2 rounded-md ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-900'} transition-colors duration-200 flex justify-between items-center`}>
                                <span>Change Password</span>
                                <ChevronRight size={20} />
                            </button>
                            <button className={`w-full text-left px-4 py-2 rounded-md ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-900'} transition-colors duration-200 flex justify-between items-center`}>
                                <span>Two-Factor Authentication</span>
                                <ChevronRight size={20} />
                            </button>
                        </div>
                    </motion.section>

                    <motion.section
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        className={`${isDarkMode ? 'bg-gray-800/50' : 'bg-white/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'}`}
                    >
                        <h2 className={`text-xl font-semibold mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Help & Support</h2>
                        <div className="space-y-4">
                            <button className={`w-full text-left px-4 py-2 rounded-md ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-900'} transition-colors duration-200 flex justify-between items-center`}>
                                <span>FAQs</span>
                                <ChevronRight size={20} />
                            </button>
                            <button className={`w-full text-left px-4 py-2 rounded-md ${isDarkMode ? 'bg-gray-700 hover:bg-gray-600 text-white' : 'bg-gray-100 hover:bg-gray-200 text-gray-900'} transition-colors duration-200 flex justify-between items-center`}>
                                <span>Contact Support</span>
                                <ChevronRight size={20} />
                            </button>
                        </div>
                    </motion.section>
                </div>
            </main>
        </div>
    )
}