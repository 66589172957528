import React, { useState, useEffect } from 'react'
import { motion, useAnimation, AnimatePresence } from 'framer-motion'
import { Eye, EyeOff, ArrowRight, Lock, Mail } from 'lucide-react'
import axios, { AxiosError } from 'axios'
import { useNavigate } from 'react-router-dom'

interface LoginResponse {
  token: string
  user: {
    id: number
    username: string
  }
}

export default function LoginPage() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [activeField, setActiveField] = useState<string | null>(null)
  const controls = useAnimation()
  const navigate = useNavigate()

  useEffect(() => {
    controls.start({
      backgroundPosition: ['0% 50%', '100% 50%', '0% 50%'],
      transition: { duration: 20, repeat: Infinity, ease: 'linear' }
    })
  }, [controls])

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsLoggingIn(true)

    try {
      // Replace this URL with your Python backend login API endpoint
      const response = await axios.post<LoginResponse>('https://api.cciq.ai/api/login', {
        email,
        password
      })

      const { token } = response.data
      // Store JWT token in localStorage
      localStorage.setItem('token', token)

      // Redirect to the dashboard after successful login
      navigate('/')
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // Type assertion to inform TypeScript that error is AxiosError
        const axiosError = error as AxiosError<{ message: string }>
        console.error('Login failed:', axiosError.response?.data?.message || axiosError.message)
        // Optionally, you can display the error message to the user
        // e.g., set an error state and show it in the UI
      } else {
        console.error('Unexpected error', error)
      }
    } finally {
      setIsLoggingIn(false)
    }
  }

  const inputVariants = {
    focus: { scale: 1.05, boxShadow: '0 0 20px rgba(0,0,0,0.1)' },
    blur: { scale: 1, boxShadow: '0 0 0px rgba(0,0,0,0)' }
  }

  return (
      <div className="relative min-h-screen bg-gray-50 flex items-center justify-center p-4">
        <motion.div
            className="absolute inset-0 z-0"
            animate={controls}
            style={{
              background: 'linear-gradient(45deg, #f0f0f0, #e0e0e0, #d0d0d0, #e0e0e0, #f0f0f0)',
              backgroundSize: '400% 400%'
            }}
        />
        <motion.div
            initial={{ opacity: 0, scale: 0.9, rotateY: -15 }}
            animate={{ opacity: 1, scale: 1, rotateY: 0 }}
            transition={{ duration: 0.8, ease: [0.23, 1, 0.32, 1] }}
            className="relative z-10 bg-white/20 backdrop-blur-2xl rounded-3xl p-8 w-full max-w-md shadow-2xl overflow-hidden perspective-1000"
        >
          <motion.div
              className="absolute inset-0 z-0"
              animate={{
                background: [
                  'radial-gradient(circle at 20% 20%, rgba(62, 62, 62, 0.05) 0%, rgba(255, 255, 255, 0) 70%)',
                  'radial-gradient(circle at 80% 80%, rgba(62, 62, 62, 0.05) 0%, rgba(255, 255, 255, 0) 70%)',
                  'radial-gradient(circle at 20% 20%, rgba(62, 62, 62, 0.05) 0%, rgba(255, 255, 255, 0) 70%)'
                ]
              }}
              transition={{ duration: 15, repeat: Infinity, ease: 'linear' }}
          />
          <div className="relative z-10">
            <motion.h1
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2, duration: 0.5 }}
                className="text-5xl font-light text-gray-800 mb-8 text-center"
            >
              Welcome
            </motion.h1>
            <form onSubmit={handleLogin} className="space-y-6">
              <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.3, duration: 0.5 }}
                  className="relative"
              >
                <motion.div
                    variants={inputVariants}
                    animate={activeField === 'email' ? 'focus' : 'blur'}
                    className="relative"
                >
                  <input
                      type="email"
                      id="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      onFocus={() => setActiveField('email')}
                      onBlur={() => setActiveField(null)}
                      className="w-full px-4 py-3 pl-12 rounded-xl bg-white/50 border border-gray-200 focus:border-gray-900 focus:ring-1 focus:ring-gray-900 focus:outline-none transition duration-200"
                      placeholder="Enter your email"
                      required
                  />
                  <Mail className="absolute left-3 top-3.5 text-gray-400" size={20} />
                </motion.div>
                <AnimatePresence>
                  {activeField === 'email' && (
                      <motion.label
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className="absolute -top-6 left-0 text-sm font-medium text-gray-700"
                      >
                        Email
                      </motion.label>
                  )}
                </AnimatePresence>
              </motion.div>
              <motion.div
                  initial={{ opacity: 0, x: 20 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ delay: 0.4, duration: 0.5 }}
                  className="relative"
              >
                <motion.div
                    variants={inputVariants}
                    animate={activeField === 'password' ? 'focus' : 'blur'}
                    className="relative"
                >
                  <input
                      type={showPassword ? 'text' : 'password'}
                      id="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      onFocus={() => setActiveField('password')}
                      onBlur={() => setActiveField(null)}
                      className="w-full px-4 py-3 pl-12 rounded-xl bg-white/50 border border-gray-200 focus:border-gray-900 focus:ring-1 focus:ring-gray-900 focus:outline-none transition duration-200"
                      placeholder="Enter your password"
                      required
                  />
                  <Lock className="absolute left-3 top-3.5 text-gray-400" size={20} />
                  <button
                      type="button"
                      onClick={() => setShowPassword(!showPassword)}
                      className="absolute right-3 top-3.5 text-gray-400 hover:text-gray-600 transition duration-200"
                  >
                    {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                  </button>
                </motion.div>
                <AnimatePresence>
                  {activeField === 'password' && (
                      <motion.label
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className="absolute -top-6 left-0 text-sm font-medium text-gray-700"
                      >
                        Password
                      </motion.label>
                  )}
                </AnimatePresence>
              </motion.div>
              <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.5, duration: 0.5 }}
              >
                <motion.button
                    type="submit"
                    disabled={isLoggingIn}
                    className={`w-full bg-gray-900 text-white font-medium py-3 px-4 rounded-xl focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition duration-200 ${
                        isLoggingIn ? 'opacity-70 cursor-not-allowed' : 'hover:bg-gray-800'
                    }`}
                    whileHover={{ scale: 1.03 }}
                    whileTap={{ scale: 0.98 }}
                >
                  <AnimatePresence mode="wait">
                    {isLoggingIn ? (
                        <motion.div
                            key="logging-in"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="flex items-center justify-center"
                        >
                          <motion.div
                              className="w-5 h-5 border-t-2 border-b-2 border-white rounded-full mr-2"
                              animate={{ rotate: 360 }}
                              transition={{ duration: 1, repeat: Infinity, ease: 'linear' }}
                          />
                          Logging in...
                        </motion.div>
                    ) : (
                        <motion.div
                            key="login"
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            className="flex items-center justify-center"
                        >
                          Login
                          <ArrowRight className="ml-2" size={20} />
                        </motion.div>
                    )}
                  </AnimatePresence>
                </motion.button>
              </motion.div>
            </form>
            <motion.p
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.6, duration: 0.5 }}
                className="mt-6 text-center text-gray-600 text-sm"
            >
              Forgot your password?{' '}
              <a href="#" className="text-gray-900 hover:underline transition duration-200">
                Reset it here
              </a>
            </motion.p>
          </div>
        </motion.div>
        <motion.div
            className="absolute bottom-4 left-4 text-gray-500 text-sm"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.7, duration: 0.5 }}
        >
          © 2024 CCIQ. All rights reserved.
        </motion.div>
      </div>
  )
}
