import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import axios from "axios";

interface AdminRouteProps {
    element: React.ReactElement;
}

const AdminRoute: React.FC<AdminRouteProps> = ({ element }) => {
    const [isAdmin, setIsAdmin] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const checkAdminStatus = async () => {
            const token = localStorage.getItem("token"); // Retrieve JWT from local storage

            if (!token) {
                setIsAdmin(false);
                setLoading(false);
                return;
            }

            try {
                // Call your API to check if the user is an admin
                const response = await axios.get("https://api.cciq.ai/api/check-admin", {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.data.isAdmin) {
                    setIsAdmin(true);
                } else {
                    setIsAdmin(false);
                }
            } catch (error) {
                console.error("Error checking admin status:", error);
                setIsAdmin(false);
            } finally {
                setLoading(false);
            }
        };

        checkAdminStatus();
    }, []);

    if (loading) {
        // Render a loading spinner or placeholder until the check is done
        return <div>Loading...</div>;
    }

    // If user is admin, render the provided element, otherwise navigate to /login
    return isAdmin ? element : <Navigate to="/login" replace />;
};

export default AdminRoute;
