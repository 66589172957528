import React, { useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { PieChart, Pie, Cell, ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, BarChart, Bar } from 'recharts'
import { TrendingUp, TrendingDown, DollarSign, Percent, ArrowUpRight, ArrowDownRight, Filter, ChevronDown, ChevronUp, Search, Home, BarChart2, PieChart as PieChartIcon, Settings, Menu, X, Sun, Moon, LogOut, Info } from 'lucide-react'

// Mock data
const portfolioOverview = {
    totalValue: 1250000,
    premiumReceived: 75000,
    openPositions: 15,
    totalPnL: 45000,
    pnlPercent: 3.6,
}

const coveredCallPositions = [
    { id: 1, underlying: 'AAPL', strike: 150, expiry: '2024-12-20', quantity: 1000, premium: 15000, currentValue: 12000, pnl: 3000 },
    { id: 2, underlying: 'GOOGL', strike: 2800, expiry: '2024-12-06', quantity: 100, premium: 22000, currentValue: 18000, pnl: 4000 },
    { id: 3, underlying: 'TSLA', strike: 750, expiry: '2024-11-15', quantity: 500, premium: 18000, currentValue: 20000, pnl: -2000 },
    { id: 4, underlying: 'AMZN', strike: 3400, expiry: '2024-11-22', quantity: 200, premium: 12000, currentValue: 10000, pnl: 2000 },
    { id: 5, underlying: 'MSFT', strike: 300, expiry: '2024-12-13', quantity: 800, premium: 8000, currentValue: 7000, pnl: 1000 },
]

const pnlHistory = [
    { date: '2024-06-01', pnl: 30000 },
    { date: '2024-07-01', pnl: 35000 },
    { date: '2024-08-01', pnl: 32000 },
    { date: '2024-09-01', pnl: 40000 },
    { date: '2024-10-01', pnl: 38000 },
    { date: '2024-11-01', pnl: 45000 },
]

const CustomTooltip = ({ active, payload, label, isDarkMode }: any) => {
    if (active && payload && payload.length) {
        return (
            <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-4 rounded-xl shadow-lg border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} text-sm`}>
                <p className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'} mb-1`}>{`${label}`}</p>
                {payload.map((entry: any, index: number) => (
                    <p key={index} className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                        {`${entry.name}: $${entry.value.toLocaleString()}`}
                    </p>
                ))}
            </div>
        )
    }
    return null
}

export default function PortfolioPage() {
    const [isDarkMode, setIsDarkMode] = useState(true)
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false)

    const menuItems = [
        { name: 'Dashboard', icon: Home, href: '/' },
        { name: 'Analytics', icon: BarChart2, href: '/analytics' },
        { name: 'Portfolio', icon: PieChartIcon, href: '/portfolio' },
        { name: 'Settings', icon: Settings, href: '/settings' },
    ]

    return (
        <div className={`min-h-screen ${isDarkMode ? 'bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100' : 'bg-gradient-to-br from-gray-50 to-white text-gray-900'} font-sans transition-colors duration-300`}>
            <nav className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-900/50' : 'bg-white/50'} backdrop-blur-xl`}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <span className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>CCIQ</span>
                        </div>
                        <div className="hidden md:flex items-center space-x-4">
                            {menuItems.map((item, index) => (
                                <motion.a
                                    key={index}
                                    href={item.href}
                                    className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'} px-3 py-2 text-sm font-medium transition-colors duration-200 flex items-center space-x-2`}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <item.icon className="w-5 h-5" />
                                    <span>{item.name}</span>
                                </motion.a>
                            ))}
                        </div>
                        <div className="flex items-center space-x-4">
                            <motion.button
                                className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-800 text-yellow-400' : 'bg-gray-200 text-gray-900'}`}
                                onClick={() => setIsDarkMode(!isDarkMode)}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
                            </motion.button>
                            <motion.button
                                className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-200 text-gray-600'}`}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                <LogOut size={20} />
                            </motion.button>
                            <div className="md:hidden">
                                <button
                                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                                    className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}
                                >
                                    <span className="sr-only">Open main menu</span>
                                    {isMobileMenuOpen ? (
                                        <X className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Menu className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            <AnimatePresence>
                {isMobileMenuOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        className={`md:hidden ${isDarkMode ? 'bg-gray-900/90' : 'bg-white/90'} backdrop-blur-xl`}
                    >
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {menuItems.map((item, index) => (
                                <a
                                    key={index}
                                    href={item.href}
                                    className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'} block px-3 py-2 rounded-md text-base font-medium`}
                                >
                                    <item.icon className="w-5 h-5 inline-block mr-2" />
                                    {item.name}
                                </a>
                            ))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                <motion.h1
                    className={`text-4xl font-light tracking-tight ${isDarkMode ? 'text-white' : 'text-gray-900'} mb-8`}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    Covered Call Portfolio
                </motion.h1>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 }}
                        className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border`}
                    >
                        <div className="flex items-center justify-between mb-4">
                            <h2 className={`text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>Total Portfolio Value</h2>
                            <DollarSign className={`w-5 h-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                        </div>
                        <div className={`text-3xl font-light mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                            ${portfolioOverview.totalValue.toLocaleString()}
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.2 }}
                        className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border`}
                    >
                        <div className="flex items-center justify-between mb-4">
                            <h2 className={`text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>Premium Received</h2>
                            <BarChart2 className={`w-5 h-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                        </div>
                        <div className={`text-3xl font-light mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                            ${portfolioOverview.premiumReceived.toLocaleString()}
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.3 }}
                        className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border`}
                    >
                        <div className="flex items-center justify-between mb-4">
                            <h2 className={`text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>Open Positions</h2>
                            <TrendingUp className={`w-5 h-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                        </div>
                        <div className={`text-3xl font-light mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                            {portfolioOverview.openPositions}
                        </div>
                    </motion.div>
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border`}
                    >
                        <div className="flex items-center justify-between mb-4">
                            <h2 className={`text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>Total P&L</h2>
                            <Percent className={`w-5 h-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                        </div>
                        <div className={`text-3xl font-light mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                            ${portfolioOverview.totalPnL.toLocaleString()}
                        </div>
                        <div className="flex items-center text-xs text-green-400 font-medium">
                            <ArrowUpRight size={12} className="mr-1" />
                            <span>{portfolioOverview.pnlPercent}%</span>
                        </div>
                    </motion.div>
                </div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.5 }}
                    className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border mb-8`}
                >
                    <h2 className={`text-xl font-light mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Covered Call Positions</h2>
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                            <tr className={`text-left text-xs font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} uppercase tracking-wider`}>
                                <th className="pb-3 pr-6">Underlying</th>
                                <th className="pb-3 pr-6">Strike</th>
                                <th className="pb-3 pr-6">Expiry</th>
                                <th className="pb-3 pr-6">Quantity</th>
                                <th className="pb-3 pr-6">Premium</th>
                                <th className="pb-3 pr-6">Current Value</th>
                                <th className="pb-3">P&L</th>
                            </tr>
                            </thead>
                            <tbody className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
                            {coveredCallPositions.map((position, index) => (
                                <tr key={index} className="text-sm">
                                    <td className={`py-3 pr-6 whitespace-nowrap font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{position.underlying}</td>
                                    <td className={`py-3 pr-6 whitespace-nowrap ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>${position.strike}</td>
                                    <td className={`py-3 pr-6 whitespace-nowrap ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>{position.expiry}</td>
                                    <td className={`py-3 pr-6 whitespace-nowrap ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>{position.quantity}</td>
                                    <td className={`py-3 pr-6 whitespace-nowrap ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>${position.premium.toLocaleString()}</td>
                                    <td className={`py-3 pr-6 whitespace-nowrap ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>${position.currentValue.toLocaleString()}</td>
                                    <td className={`py-3 whitespace-nowrap ${position.pnl >= 0 ? 'text-green-400' : 'text-red-400'}`}>
                                        {position.pnl >= 0 ? '+' : '-'}${Math.abs(position.pnl).toLocaleString()}
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </motion.div>

                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border`}
                >
                    <h2 className={`text-xl font-light mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>P&L History</h2>
                    <div className="h-64">
                        <ResponsiveContainer width="100%" height="100%">
                            <LineChart data={pnlHistory} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" vertical={false} stroke={isDarkMode ? '#4B5563' : '#E5E7EB'} />
                                <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fill: isDarkMode ? '#9CA3AF' : '#4B5563', fontSize: 12 }} />
                                <YAxis axisLine={false} tickLine={false} tick={{ fill: isDarkMode ? '#9CA3AF' : '#4B5563', fontSize: 12 }} />
                                <Tooltip content={<CustomTooltip isDarkMode={isDarkMode} />} />
                                <Line type="monotone" dataKey="pnl" stroke="#10B981" strokeWidth={2} dot={false} />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                </motion.div>
            </main>
        </div>
    )
}