// src/analytics.tsx

import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
    LineChart,
    Line,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';
import {
    TrendingUp,
    Percent,
    ArrowUpRight,
    Filter,
    ChevronDown,
    ChevronUp,
    Search,
    Home,
    BarChart2,
    PieChart as PieChartIcon,
    Settings,
    Menu,
    X,
    Sun,
    Moon,
    LogOut,
    Check,
    AlertCircle,
} from 'lucide-react';

// Define Interfaces
interface ContractPrediction {
    id: number;
    ticker: string;
    strike: number;
    expiry: string;
    premium: number;
    confidence: number;
    prediction: boolean;
    volume: number;
    openInterest: number;
    impliedVolatility: number;
}

interface HistoricalAccuracy {
    date: string;
    accuracy: number;
    profitFactor: number;
}

interface PredictionDistribution {
    confidence: string;
    count: number;
}

interface MenuItem {
    name: string;
    icon: React.ComponentType<React.SVGProps<SVGSVGElement>>;
    href: string;
}

interface CustomTooltipProps {
    active?: boolean;
    payload?: any[];
    label?: string;
    isDarkMode: boolean;
}

// Mock data
const contractPredictions: ContractPrediction[] = [
    { id: 1, ticker: 'AAPL', strike: 150, expiry: '2024-12-20', premium: 3.5, confidence: 0.85, prediction: true, volume: 1500, openInterest: 5000, impliedVolatility: 0.25 },
    { id: 2, ticker: 'GOOGL', strike: 2800, expiry: '2024-12-06', premium: 45.25, confidence: 0.72, prediction: true, volume: 800, openInterest: 3000, impliedVolatility: 0.3 },
    { id: 3, ticker: 'MSFT', strike: 300, expiry: '2024-11-15', premium: 5.75, confidence: 0.91, prediction: true, volume: 2000, openInterest: 7500, impliedVolatility: 0.22 },
    { id: 4, ticker: 'AMZN', strike: 3400, expiry: '2024-11-22', premium: 62.5, confidence: 0.68, prediction: false, volume: 600, openInterest: 2500, impliedVolatility: 0.35 },
    { id: 5, ticker: 'TSLA', strike: 750, expiry: '2024-12-13', premium: 18.25, confidence: 0.79, prediction: true, volume: 3000, openInterest: 10000, impliedVolatility: 0.4 },
    { id: 6, ticker: 'META', strike: 330, expiry: '2024-12-27', premium: 8.75, confidence: 0.83, prediction: true, volume: 1200, openInterest: 4500, impliedVolatility: 0.28 },
    { id: 7, ticker: 'NVDA', strike: 220, expiry: '2024-11-08', premium: 7.5, confidence: 0.88, prediction: true, volume: 1800, openInterest: 6000, impliedVolatility: 0.32 },
    { id: 8, ticker: 'JPM', strike: 155, expiry: '2024-12-20', premium: 3.25, confidence: 0.76, prediction: false, volume: 500, openInterest: 2000, impliedVolatility: 0.2 },
    { id: 9, ticker: 'V', strike: 230, expiry: '2024-11-29', premium: 4.5, confidence: 0.82, prediction: true, volume: 900, openInterest: 3500, impliedVolatility: 0.23 },
    { id: 10, ticker: 'JNJ', strike: 170, expiry: '2024-12-06', premium: 2.75, confidence: 0.71, prediction: false, volume: 400, openInterest: 1500, impliedVolatility: 0.18 },
];

const historicalAccuracy: HistoricalAccuracy[] = [
    { date: '2024-01-01', accuracy: 0.82, profitFactor: 1.8 },
    { date: '2024-02-01', accuracy: 0.85, profitFactor: 2.1 },
    { date: '2024-03-01', accuracy: 0.8, profitFactor: 1.7 },
    { date: '2024-04-01', accuracy: 0.88, profitFactor: 2.4 },
    { date: '2024-05-01', accuracy: 0.86, profitFactor: 2.2 },
    { date: '2024-06-01', accuracy: 0.84, profitFactor: 2.0 },
    { date: '2024-07-01', accuracy: 0.87, profitFactor: 2.3 },
    { date: '2024-08-01', accuracy: 0.89, profitFactor: 2.5 },
];

const predictionDistribution: PredictionDistribution[] = [
    { confidence: '90-100', count: 35 },
    { confidence: '80-90', count: 42 },
    { confidence: '70-80', count: 28 },
    { confidence: '60-70', count: 15 },
    { confidence: '50-60', count: 7 },
];

// Custom Tooltip Component
const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label, isDarkMode }) => {
    if (active && payload && payload.length) {
        return (
            <div className={`${isDarkMode ? 'bg-gray-800' : 'bg-white'} p-4 rounded-xl shadow-lg border ${isDarkMode ? 'border-gray-700' : 'border-gray-200'} text-sm`}>
                <p className={`font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'} mb-1`}>{`${label}`}</p>
                {payload.map((entry, index) => (
                    <p key={index} className={isDarkMode ? 'text-gray-300' : 'text-gray-600'}>
                        {`${entry.name.charAt(0).toUpperCase() + entry.name.slice(1)}: ${
                            typeof entry.value === 'number' ? entry.value.toFixed(2) : entry.value
                        }`}
                    </p>
                ))}
            </div>
        );
    }
    return null;
};

export default function AnalyticsPage() {
    // Define SortKey type
    type SortKey = keyof Pick<ContractPrediction, 'ticker' | 'strike' | 'premium' | 'confidence'>;

    // State Definitions
    const [isDarkMode, setIsDarkMode] = useState<boolean>(true);
    const [sortBy, setSortBy] = useState<SortKey>('confidence');
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [filterConfidence, setFilterConfidence] = useState<number>(0);
    const [selectedContract, setSelectedContract] = useState<ContractPrediction | null>(null);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState<boolean>(false);
    const [searchTerm, setSearchTerm] = useState<string>('');

    // Sorting Function
    const handleSort = (key: SortKey) => {
        if (sortBy === key) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(key);
            setSortOrder('asc'); // Default to ascending when changing the sort key
        }
    };

    // Filter and Sort Predictions
    const filteredAndSortedPredictions = contractPredictions
        .filter(prediction =>
            prediction.confidence >= filterConfidence &&
            (searchTerm === '' ||
                prediction.ticker.toLowerCase().includes(searchTerm.toLowerCase()) ||
                prediction.expiry.includes(searchTerm))
        )
        .sort((a, b) => {
            let comparison = 0;
            const aValue = a[sortBy];
            const bValue = b[sortBy];

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                comparison = aValue.localeCompare(bValue);
            } else if (typeof aValue === 'number' && typeof bValue === 'number') {
                comparison = aValue - bValue;
            }

            return sortOrder === 'asc' ? comparison : -comparison;
        });

    // Menu Items
    const menuItems: MenuItem[] = [
        { name: 'Dashboard', icon: Home, href: '/' },
        { name: 'Analytics', icon: BarChart2, href: '/analytics' },
        { name: 'Portfolio', icon: PieChartIcon, href: '/portfolio' },
        { name: 'Settings', icon: Settings, href: '/settings' },
    ];

    // Toggle Dark Mode
    const toggleDarkMode = () => {
        setIsDarkMode(!isDarkMode);
    };

    return (
        <div className={`min-h-screen ${isDarkMode ? 'bg-gradient-to-br from-gray-900 to-gray-800 text-gray-100' : 'bg-gradient-to-br from-gray-50 to-white text-gray-900'} font-sans transition-colors duration-300`}>
            {/* Navigation */}
            <nav className={`sticky top-0 z-50 ${isDarkMode ? 'bg-gray-900/50' : 'bg-white/50'} backdrop-blur-xl`}>
                <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                    <div className="flex items-center justify-between h-16">
                        <div className="flex items-center">
                            <span className={`text-2xl font-bold ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>CCIQ</span>
                        </div>
                        <div className="hidden md:flex items-center space-x-4">
                            {menuItems.map((item, index) => (
                                <motion.a
                                    key={index}
                                    href={item.href}
                                    className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'} px-3 py-2 text-sm font-medium transition-colors duration-200 flex items-center space-x-2`}
                                    whileHover={{ scale: 1.05 }}
                                    whileTap={{ scale: 0.95 }}
                                >
                                    <item.icon className="w-5 h-5" />
                                    <span>{item.name}</span>
                                </motion.a>
                            ))}
                        </div>
                        <div className="flex items-center space-x-4">
                            <motion.button
                                className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-800 text-yellow-400' : 'bg-gray-200 text-gray-900'}`}
                                onClick={toggleDarkMode}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                {isDarkMode ? <Sun size={20} /> : <Moon size={20} />}
                            </motion.button>
                            <motion.button
                                className={`p-2 rounded-full ${isDarkMode ? 'bg-gray-800 text-gray-300' : 'bg-gray-200 text-gray-600'}`}
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                            >
                                <LogOut size={20} />
                            </motion.button>
                            <div className="md:hidden">
                                <button
                                    onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                                    className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white`}
                                >
                                    <span className="sr-only">Open main menu</span>
                                    {isMobileMenuOpen ? (
                                        <X className="block h-6 w-6" aria-hidden="true" />
                                    ) : (
                                        <Menu className="block h-6 w-6" aria-hidden="true" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>

            {/* Mobile Menu */}
            <AnimatePresence>
                {isMobileMenuOpen && (
                    <motion.div
                        initial={{ opacity: 0, height: 0 }}
                        animate={{ opacity: 1, height: 'auto' }}
                        exit={{ opacity: 0, height: 0 }}
                        className={`md:hidden ${isDarkMode ? 'bg-gray-900/90' : 'bg-white/90'} backdrop-blur-xl`}
                    >
                        <div className="px-2 pt-2 pb-3 space-y-1">
                            {menuItems.map((item, index) => (
                                <a
                                    key={index}
                                    href={item.href}
                                    className={`${isDarkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-900'} block px-3 py-2 rounded-md text-base font-medium`}
                                >
                                    <item.icon className="w-5 h-5 inline-block mr-2" />
                                    {item.name}
                                </a>
                            ))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>

            {/* Main Content */}
            <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
                {/* Page Title */}
                <motion.h1
                    className={`text-4xl font-light tracking-tight ${isDarkMode ? 'text-white' : 'text-gray-900'} mb-8`}
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5 }}
                >
                    Call Options Analytics
                </motion.h1>

                {/* Summary Cards */}
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
                    {[
                        { title: 'Model Accuracy', value: '86.5%', change: '+2.3%', changeText: 'vs Last Month', icon: Percent },
                        { title: 'Profitable Predictions', value: '73.2%', change: '+1.5%', changeText: 'vs Last Month', icon: TrendingUp },
                        { title: 'Active Predictions', value: '127', change: '+15', changeText: 'New This Week', icon: BarChart2 },
                        { title: 'Avg Confidence', value: '82.7%', change: '+1.2%', changeText: 'vs Last Month', icon: Check },
                    ].map((item, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border`}
                        >
                            <div className="flex items-center justify-between mb-4">
                                <h2 className={`text-sm font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>{item.title}</h2>
                                <item.icon className={`w-5 h-5 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`} />
                            </div>
                            <div className={`text-3xl font-light mb-2 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{item.value}</div>
                            <div className="flex items-center text-xs text-green-400 font-medium">
                                <ArrowUpRight size={12} className="mr-1" />
                                <span>{item.change} {item.changeText}</span>
                            </div>
                        </motion.div>
                    ))}
                </div>

                {/* Charts */}
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-8">
                    {/* Historical Performance Metrics */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.4 }}
                        className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border`}
                    >
                        <h2 className={`text-xl font-light mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Historical Performance Metrics</h2>
                        <div className="h-64">
                            <ResponsiveContainer width="100%" height="100%">
                                <LineChart data={historicalAccuracy} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                    <CartesianGrid strokeDasharray="3 3" vertical={false} stroke={isDarkMode ? '#4B5563' : '#E5E7EB'} />
                                    <XAxis dataKey="date" axisLine={false} tickLine={false} tick={{ fill: isDarkMode ? '#9CA3AF' : '#4B5563', fontSize: 12 }} />
                                    <YAxis axisLine={false} tickLine={false} tick={{ fill: isDarkMode ? '#9CA3AF' : '#4B5563', fontSize: 12 }} />
                                    <Tooltip content={(props) => <CustomTooltip {...props} isDarkMode={isDarkMode} />} />
                                    <Line type="monotone" dataKey="accuracy" stroke="#10B981" strokeWidth={2} dot={false} />
                                    <Line type="monotone" dataKey="profitFactor" stroke="#3B82F6" strokeWidth={2} dot={false} />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                        <div className="flex justify-between items-center mt-4">
                            <div className="flex items-center">
                                <div className="w-3 h-3 bg-green-500 rounded-full mr-2"></div>
                                <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>Accuracy</span>
                            </div>
                            <div className="flex items-center">
                                <div className="w-3 h-3 bg-blue-500 rounded-full mr-2"></div>
                                <span className={`text-xs ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>Profit Factor</span>
                            </div>
                        </div>
                    </motion.div>

                    {/* Prediction Confidence Distribution */}
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: 0.5 }}
                        className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border`}
                    >
                        <h2 className={`text-xl font-light mb-6 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Prediction Confidence Distribution</h2>
                        <div className="h-64">
                            <ResponsiveContainer width="100%" height="100%">
                                <BarChart data={predictionDistribution} margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                    <CartesianGrid strokeDasharray="3 3" vertical={false} stroke={isDarkMode ? '#4B5563' : '#E5E7EB'} />
                                    <XAxis dataKey="confidence" axisLine={false} tickLine={false} tick={{ fill: isDarkMode ? '#9CA3AF' : '#4B5563', fontSize: 12 }} />
                                    <YAxis axisLine={false} tickLine={false} tick={{ fill: isDarkMode ? '#9CA3AF' : '#4B5563', fontSize: 12 }} />
                                    <Tooltip content={(props) => <CustomTooltip {...props} isDarkMode={isDarkMode} />} />
                                    <Bar dataKey="count" fill="#3B82F6" />
                                </BarChart>
                            </ResponsiveContainer>
                        </div>
                        <p className={`text-sm mt-4 ${isDarkMode ? 'text-gray-400' : 'text-gray-500'}`}>
                            Distribution of prediction confidence levels across all active predictions.
                        </p>
                    </motion.div>
                </div>

                {/* Call Option Predictions Table */}
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.5, delay: 0.6 }}
                    className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border mb-8`}
                >
                    <div className="flex justify-between items-center mb-6">
                        <h2 className={`text-xl font-light ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>Call Option Predictions</h2>
                        <div className="flex items-center space-x-4">
                            {/* Filter Dropdown */}
                            <div className="relative">
                                <select
                                    className={`appearance-none ${isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700'} border border-gray-300 rounded-md py-2 pl-3 pr-8 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                                    value={filterConfidence}
                                    onChange={(e) => setFilterConfidence(parseFloat(e.target.value))}
                                >
                                    <option value={0}>All Confidence</option>
                                    <option value={0.7}>&gt; 70%</option>
                                    <option value={0.8}>&gt; 80%</option>
                                    <option value={0.9}>&gt; 90%</option>
                                </select>
                                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                    <Filter size={16} />
                                </div>
                            </div>

                            {/* Search Input */}
                            <div className="relative">
                                <input
                                    type="text"
                                    placeholder="Search contracts..."
                                    className={`${isDarkMode ? 'bg-gray-700 text-gray-300' : 'bg-gray-100 text-gray-700'} border border-gray-300 rounded-md py-2 pl-10 pr-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                                    <Search size={16} className={isDarkMode ? 'text-gray-500' : 'text-gray-400'} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="w-full">
                            <thead>
                            <tr className={`text-left text-xs font-medium ${isDarkMode ? 'text-gray-400' : 'text-gray-500'} uppercase tracking-wider`}>
                                <th className="pb-3 pr-6">
                                    <button className="flex items-center" onClick={() => handleSort('ticker')}>
                                        Ticker
                                        {sortBy === 'ticker' && (sortOrder === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />)}
                                    </button>
                                </th>
                                <th className="pb-3 pr-6">
                                    <button className="flex items-center" onClick={() => handleSort('strike')}>
                                        Strike
                                        {sortBy === 'strike' && (sortOrder === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />)}
                                    </button>
                                </th>
                                <th className="pb-3 pr-6">Expiry</th>
                                <th className="pb-3 pr-6">
                                    <button className="flex items-center" onClick={() => handleSort('premium')}>
                                        Premium
                                        {sortBy === 'premium' && (sortOrder === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />)}
                                    </button>
                                </th>
                                <th className="pb-3 pr-6">
                                    <button className="flex items-center" onClick={() => handleSort('confidence')}>
                                        Confidence
                                        {sortBy === 'confidence' && (sortOrder === 'asc' ? <ChevronUp size={16} /> : <ChevronDown size={16} />)}
                                    </button>
                                </th>
                                <th className="pb-3">Prediction</th>
                            </tr>
                            </thead>
                            <tbody className={`divide-y ${isDarkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
                            {filteredAndSortedPredictions.map((contract, index) => (
                                <motion.tr
                                    key={contract.id}
                                    className="text-sm cursor-pointer hover:bg-gray-700/10"
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 0.3, delay: index * 0.05 }}
                                    onClick={() => setSelectedContract(contract)}
                                >
                                    <td className={`py-3 pr-6 whitespace-nowrap font-medium ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>{contract.ticker}</td>
                                    <td className={`py-3 pr-6 whitespace-nowrap ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>${contract.strike.toFixed(2)}</td>
                                    <td className={`py-3 pr-6 whitespace-nowrap ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>{contract.expiry}</td>
                                    <td className={`py-3 pr-6 whitespace-nowrap ${isDarkMode ? 'text-gray-300' : 'text-gray-500'}`}>${contract.premium.toFixed(2)}</td>
                                    <td className="py-3 pr-6 whitespace-nowrap">
                                        <div className="flex items-center">
                                            <div className={`w-16 ${isDarkMode ? 'bg-gray-700' : 'bg-gray-200'} rounded-full h-2 mr-2`}>
                                                <div
                                                    className={`h-2 rounded-full ${
                                                        contract.confidence > 0.8 ? 'bg-green-500' :
                                                            contract.confidence > 0.6 ? 'bg-yellow-500' : 'bg-red-500'
                                                    }`}
                                                    style={{ width: `${contract.confidence * 100}%` }}
                                                ></div>
                                            </div>
                                            <span className={isDarkMode ? 'text-gray-300' : 'text-gray-500'}>{(contract.confidence * 100).toFixed(0)}%</span>
                                        </div>
                                    </td>
                                    <td className={`py-3 whitespace-nowrap ${contract.prediction ? 'text-green-400' : 'text-red-400'}`}>
                                        {contract.prediction ? <Check size={20} /> : <AlertCircle size={20} />}
                                    </td>
                                </motion.tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </motion.div>

                {/* Selected Contract Details */}
                {selectedContract && (
                    <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5 }}
                        className={`${isDarkMode ? 'bg-gray-800/50 border-gray-700/50' : 'bg-white/50 border-gray-200/50'} backdrop-blur-xl p-6 rounded-2xl shadow-lg hover:shadow-xl transition-all duration-300 border mb-8`}
                    >
                        <h2 className={`text-xl font-light mb-4 ${isDarkMode ? 'text-white' : 'text-gray-900'}`}>
                            Contract Details: {selectedContract.ticker} ${selectedContract.strike} Call
                        </h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div>
                                <h3 className="text-lg font-medium mb-2">Contract Information</h3>
                                <ul className={`space-y-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                                    <li>Strike Price: ${selectedContract.strike.toFixed(2)}</li>
                                    <li>Expiry Date: {selectedContract.expiry}</li>
                                    <li>Premium: ${selectedContract.premium.toFixed(2)}</li>
                                    <li>Prediction: {selectedContract.prediction ? 'Profitable' : 'Not Profitable'}</li>
                                    <li>Confidence: {(selectedContract.confidence * 100).toFixed(2)}%</li>
                                    <li>Volume: {selectedContract.volume.toLocaleString()}</li>
                                    <li>Open Interest: {selectedContract.openInterest.toLocaleString()}</li>
                                    <li>Implied Volatility: {(selectedContract.impliedVolatility * 100).toFixed(2)}%</li>
                                </ul>
                            </div>
                            <div>
                                <h3 className="text-lg font-medium mb-2">Market Data</h3>
                                <ul className={`space-y-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
                                    <li>Current Stock Price: ${(selectedContract.strike * (1 + (Math.random() - 0.5) * 0.1)).toFixed(2)}</li>
                                    <li>
                                        Distance to Strike: {((selectedContract.strike - selectedContract.strike * (1 + (Math.random() - 0.5) * 0.1)) / (selectedContract.strike * (1 + (Math.random() - 0.5) * 0.1)) * 100).toFixed(2)}%
                                    </li>
                                    <li>
                                        Days to Expiration: {Math.floor((new Date(selectedContract.expiry).getTime() - new Date().getTime()) / (1000 * 60 * 60 * 24))}
                                    </li>
                                    <li>Theta: {(-0.05 * Math.random()).toFixed(3)}</li>
                                    <li>Delta: {(0.5 + 0.3 * Math.random()).toFixed(2)}</li>
                                    <li>Gamma: {(0.02 * Math.random()).toFixed(3)}</li>
                                    <li>Vega: {(0.1 + 0.1 * Math.random()).toFixed(2)}</li>
                                </ul>
                            </div>
                        </div>
                    </motion.div>
                )}
            </main>
        </div>
    );
}
